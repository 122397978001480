<template>
  <div class="d-grid h-100">
    <hsLoading />
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import { mapActions, mapState } from 'vuex';

export default {
  components: { hsLoading },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    ...mapActions('school', ['getParams']),
  },
  created() {
    this.getParams({ subDomain: this.selectedSchool.subdomain })
      .then(() => {
        const { name, ...query } = this.$route.query;
        this.$router.push({ ...this.$route, query, name: name || 'MenuHeaderLayout' });
      })
      .catch(() => this.$router.push({ name: 'Logout' }));
  },
};
</script>
